import React from 'react';
import { useVideo } from 'react-use';
import tw from 'twin.macro';

import STATIC_IMAGES from '../data/staticImages';
import { ReactComponent as IconPlay } from './Icon/play.svg';
import { FOCUS_RING_CSS } from './Styled';

const Video = () => {
  const [video, state, controls] = useVideo(
    <video
      controls={false}
      muted
      playsInline
      poster={STATIC_IMAGES.videoPoster}
      preload="auto"
      tw="bg-purple-100 object-cover absolute w-full h-full rounded-2xl shadow-lg border-4 border-purple-700"
    >
      <source src="/chartsy.mp4" type="video/mp4" />
      Sorry, your browser does not support embedded videos.
    </video>,
  );
  return (
    <button
      onClick={async () => {
        if (state.paused) {
          await controls.play();
        } else {
          controls.pause();
        }
      }}
      css={FOCUS_RING_CSS}
      tw="w-full aspect-w-16 aspect-h-9 grid place-items-center rounded-2xl"
      type="button"
    >
      <span tw="sr-only">play video</span>
      {video}
      <IconPlay css={!state.paused && tw`hidden`} tw="w-20 h-12 m-auto" />
    </button>
  );
};

export default Video;
