import React from 'react';
import 'twin.macro';

import { CollectionProductFragment, Scalars } from '../generatedGraphql';
import claimBackground from '../images/claimBackground.svg';
import ButtonLink from './ButtonLink';
import ResourceLink from './ResourceLink';
import ThemeImage from './ThemeImage';

interface ProductClaimProps {
  className?: string;
  product: CollectionProductFragment;
}

const ProductClaim = ({ className, product }: ProductClaimProps) => {
  const firstTheme = product.themes_public[0];

  return (
    <div
      className={className}
      // Padding to make up for negative margins below
      tw="grid sm:grid-cols-2 gap-16 text-purple-600 py-12"
    >
      <ResourceLink
        css={{ backgroundImage: `url(${claimBackground})` }}
        to={{ productSlug: product.slug, type: 'signUp' }}
        tw="overflow-hidden grid place-items-center order-2 py-24 -my-24 sm:order-1 bg-center bg-no-repeat bg-contain sm:bg-cover"
      >
        {firstTheme && (
          <ThemeImage
            // Casting due to Hasura view columns being all nullable
            chartDimensions={product.chart_public as Scalars['dimensions']}
            name={product.name}
            themePublic={firstTheme}
            tw="w-64 h-64 lg:(h-72 w-72) xl:(h-96 w-96) rounded-3xl overflow-hidden transform[rotate(-10deg)] shadow-lg"
          />
        )}
      </ResourceLink>
      <div tw="sm:order-2">
        <h1 tw="font-serif text-3xl sm:text-4xl">
          Try the next generation of cross stitch
        </h1>
        <ul tw="text-lg md:text-xl lg:text-2xl list-disc py-2 pr-2 pl-4 ml-2 mt-4 sm:mt-8">
          <li>Change thread and fabric color</li>
          <li>Automatic frame fit preview</li>
          <li>Track your progress from any device</li>
        </ul>
        <ButtonLink
          hue="vibrant"
          size="large"
          to={{ productSlug: product.slug, type: 'signUp' }}
          tw="mt-4"
        >
          Claim Your Free Chart
        </ButtonLink>
      </div>
    </div>
  );
};

export default ProductClaim;
