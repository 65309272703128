import { TinyColor } from '@ctrl/tinycolor';

import { rgbToCssColor } from '@chartsy/shared/utils';

import { Scalars } from '../generatedGraphql';

export default function shopColors(color: Scalars['_rgb_int']) {
  const baseColor = rgbToCssColor(color);
  const tinyColor = new TinyColor(baseColor);
  return {
    baseColor: tinyColor,
    backgroundColor: tinyColor.tint(90),
    gradientStart: tinyColor.tint(90),
    gradientEnd: tinyColor.tint(60),
    textColor: tinyColor.shade(50),
  };
}
