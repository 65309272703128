import React from 'react';
import 'twin.macro';

import { PathResources } from '@chartsy/shared/utils';

import { CollectionProductFragment, Scalars } from '../generatedGraphql';
import formatPrice from '../utils/formatPrice';
import ResourceLink from './ResourceLink';
import ThemeImage from './ThemeImage';

const ProductListItemMinimal = ({
  backgroundColor,
  chartDimensions,
  className,
  dimBackground = false,
  disableLink = false,
  hidePrice = false,
  product: { themes_public: themes, name, price, slug },
  textColor,
}: {
  backgroundColor: string;
  chartDimensions: Scalars['dimensions'];
  className?: string;
  dimBackground?: boolean;
  disableLink?: boolean;
  hidePrice?: boolean;
  product: CollectionProductFragment;
  textColor: string;
}) => {
  const firstTheme = themes[0];

  if (!firstTheme) {
    // TODO log error
    return <div>Missing theme for product {slug}</div>;
  }

  const to: PathResources = { productSlug: slug, type: 'product' };

  return (
    <ResourceLink
      className={className}
      disableLink={disableLink}
      to={to}
      tw="relative flex"
    >
      <ThemeImage
        chartDimensions={chartDimensions}
        css={{ '--tw-ring-color': backgroundColor }}
        dimBackground={dimBackground}
        name={name}
        themePublic={firstTheme}
        tw="aspect-h-1 aspect-w-1 md:ring-4 rounded-lg sm:rounded-xl md:rounded-2xl overflow-hidden"
      />
      {!hidePrice && (
        <div
          css={{ backgroundColor, color: textColor }}
          tw="font-bold absolute left-0 bottom-0 text-sm px-1.5 py-0.5 sm:text-base md:(text-lg px-2 py-1) rounded-tr-2xl"
        >
          {formatPrice(price)}
        </div>
      )}
    </ResourceLink>
  );
};

export default ProductListItemMinimal;
