import React from 'react';
import 'twin.macro';

import { CollectionProductFragment, Scalars } from '../generatedGraphql';
import shopColors from '../utils/shopColors';
import ProductListItemMinimal from './ProductListItemMinimal';
import ResourceLink from './ResourceLink';
import ShopIcon from './ShopIcon';

const MINIMUM_PRODUCTS = 9;

interface ShopCardProps {
  className?: string;
  color: Scalars['_rgb_int'];
  image: string;
  name: string;
  products: CollectionProductFragment[];
  slug: string;
}

const ShopCard = ({
  className,
  color,
  image,
  name,
  slug,
  products,
}: ShopCardProps) => {
  const colors = shopColors(color);
  const backgroundImage = `linear-gradient(to right, ${colors.gradientStart.toHexString()}, ${colors.gradientEnd.toHexString()})`;
  const finalProducts = products;
  if (products.length < MINIMUM_PRODUCTS && products.length > 0) {
    const amountMissing = MINIMUM_PRODUCTS - products.length;
    for (let i = 0; i < amountMissing; i += 1) {
      const nextProduct = products[i % products.length];
      if (!nextProduct) {
        throw new Error('Unexpected missing product');
      }
      finalProducts.push(nextProduct);
    }
  }

  return (
    <ResourceLink
      to={{ shopSlug: slug, type: 'shop' }}
      tw="flex flex-col relative rounded-3xl overflow-hidden"
    >
      <div css={{ backgroundImage }} tw="relative overflow-hidden flex-grow">
        <ul
          className={className}
          tw="grid grid-cols-3 gap[4%] transform[rotate(-10deg)]"
        >
          {finalProducts.map((product, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${product.id}-${index}`}>
              <ProductListItemMinimal
                backgroundColor={colors.baseColor.toHexString()}
                // Casting due to Hasura view columns being all nullable
                chartDimensions={product.chart_public as Scalars['dimensions']}
                dimBackground
                disableLink
                hidePrice
                product={product}
                textColor={colors.textColor.toHexString()}
              />
            </li>
          ))}
        </ul>
      </div>
      <div css={{ backgroundImage }} tw="flex items-center p-4">
        <ShopIcon
          color={color}
          name={name}
          src={image}
          tw="h-10 w-10 md:(h-16 w-16)"
        />
        <h1 tw="font-serif ml-4 text-xl md:text-3xl">{name}</h1>
      </div>
    </ResourceLink>
  );
};

export default ShopCard;
