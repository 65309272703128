import React from 'react';
import 'twin.macro';

import { ExploreShopsShopFragment } from '../generatedGraphql';
import shopColors from '../utils/shopColors';
import BackgroundShop from './Background/Shop';
import ResourceLink from './ResourceLink';
import ShopIcon from './ShopIcon';

interface ShopGridProps {
  className?: string;
  shops: ExploreShopsShopFragment[];
}

const ShopGrid = ({ className, shops }: ShopGridProps) => (
  <ul
    className={className}
    tw="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-12"
  >
    {shops.map(
      ({
        background_file: backgroundFile,
        color,
        icon_file: iconFile,
        id,
        name,
        slug,
      }) => {
        const colors = shopColors(color);
        return (
          <li key={id} tw="flex w-full">
            <ResourceLink
              to={{ shopSlug: slug, type: 'shop' }}
              tw="relative flex flex-col w-full items-center rounded-2xl p-3 overflow-hidden"
            >
              <BackgroundShop
                color={colors.baseColor}
                image={backgroundFile.s3_url}
              />
              <div tw="relative flex-grow flex flex-col items-center">
                <ShopIcon
                  color={color}
                  name={name}
                  src={iconFile.s3_url}
                  tw="h-16 w-16"
                />
                <div
                  css={{ color: colors.textColor.toHexString() }}
                  tw="text-xl font-serif mt-4 text-center overflow-hidden display[-webkit-box] -webkit-box-orient[vertical] -webkit-line-clamp[3]"
                >
                  {name}
                </div>
              </div>
              <div tw="relative rounded-full w-full px-4 py-2 bg-white font-bold mt-8 text-center hover:bg-gray-50">
                View Shop
              </div>
            </ResourceLink>
          </li>
        );
      },
    )}
  </ul>
);

export default ShopGrid;
