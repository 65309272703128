import { TinyColor } from '@ctrl/tinycolor';

import { rgbToCssColor } from '@chartsy/shared/utils';

import { Scalars } from '../generatedGraphql';

function darkenOrLighten({
  amount,
  color,
  step = 10,
  type,
}: {
  amount: number;
  color: TinyColor;
  step?: number;
  type: 'darken' | 'lighten';
}) {
  for (let i = amount; i > 0; i -= step) {
    const newColor = color[type](i);
    // Try to avoid white
    if (!newColor.equals(0xffffff) || i - step < 0) {
      return newColor;
    }
  }

  // Should never happen
  return color;
}

export default function collectionOrTagColors(color: Scalars['_rgb_int']) {
  const baseColor = rgbToCssColor(color);
  const tinyColor = new TinyColor(baseColor);
  const isDark = tinyColor.isDark();

  return {
    baseColor: tinyColor,
    backgroundColor: isDark
      ? darkenOrLighten({
          amount: 60,
          color: tinyColor.saturate(50),
          type: 'lighten',
        })
      : darkenOrLighten({
          amount: 30,
          color: tinyColor.saturate(50),
          type: 'darken',
        }),
    tertiaryColor: isDark
      ? darkenOrLighten({
          amount: 40,
          color: tinyColor.desaturate(20),
          type: 'lighten',
        })
      : darkenOrLighten({
          amount: 20,
          color: tinyColor.desaturate(10),
          type: 'darken',
        }),
  };
}
