import React from 'react';
import tw from 'twin.macro';

import { Tags } from '../generatedGraphql';
import collectionOrTagColors from '../utils/collectionOrTagColors';
import ResourceLink from './ResourceLink';
import TagIcon from './Tag/Icon';

interface TagGridProps {
  className?: string;
  tags: Pick<Tags, 'id' | 'name' | 'slug' | 'color'>[];
  size: 'large' | 'normal';
}

const TagGrid = ({ className, tags, size }: TagGridProps) => (
  <ul
    css={
      size === 'large'
        ? tw`grid-cols-3 md:grid-cols-5 lg:grid-cols-5 gap-6 lg:gap-12`
        : tw`grid-cols-3 lg:grid-cols-4 gap-3`
    }
    className={className}
    tw="grid"
  >
    {tags.map(({ color, id, name, slug }) => {
      const colors = collectionOrTagColors(color);
      return (
        <li key={id}>
          <ResourceLink
            css={[
              { backgroundColor: colors.backgroundColor.toHexString() },
              size === 'normal' ? tw`p-4` : tw`p-4 lg:p-8`,
            ]}
            to={{ tagSlug: slug, type: 'tag' }}
            tw="flex flex-col rounded-2xl"
          >
            <TagIcon
              css={
                size === 'normal' ? tw`h-8 w-8` : tw`h-8 w-8 lg:h-14 lg:w-14`
              }
              color={colors.baseColor.toHexString()}
              slug={slug}
            />
            <div
              css={[
                { color: colors.baseColor.toHexString() },
                size === 'normal'
                  ? tw`mt-6`
                  : tw`sm:text-xl lg:text-2xl xl:text-3xl mt-6 lg:mt-12`,
              ]}
              tw="overflow-hidden overflow-ellipsis"
            >
              {name}
            </div>
          </ResourceLink>
        </li>
      );
    })}
  </ul>
);

export default TagGrid;
