import React from 'react';

function BackgroundHomePage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={1785}
      height={442}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 309.861c821.224-345.605 973.073 233.196 1785 0V-199.73H0v509.591z"
        fill="url(#homeBackground_svg__paint0_linear)"
        fillOpacity={0.5}
      />
      <path
        d="M1785 377.316C963.776-13.923 811.927 641.304 0 377.317v-576.879h1785v576.878z"
        fill="url(#homeBackground_svg__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="homeBackground_svg__paint0_linear"
          x1={10.476}
          y1={182.977}
          x2={1784.88}
          y2={164.53}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E7CEF8" />
          <stop offset={1} stopColor="#FBF5FF" />
        </linearGradient>
        <linearGradient
          id="homeBackground_svg__paint1_linear"
          x1={1785}
          y1={250.413}
          x2={0}
          y2={250.413}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBF5FF" />
          <stop offset={1} stopColor="#F5E8FD" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BackgroundHomePage;
