import { TinyColor } from '@ctrl/tinycolor';
import Image from 'next/image';
import React from 'react';
import 'twin.macro';

interface BackgroundShopProps {
  color: TinyColor;
  image: string;
}

const BackgroundShop = ({ color, image }: BackgroundShopProps) => (
  <>
    <Image
      alt="shop background image"
      layout="fill"
      objectFit="cover"
      src={image}
      tw="opacity-10"
    />
    <div
      css={{ backgroundColor: color.toHexString() }}
      tw="absolute inset-0 w-full h-full opacity-5"
    />
  </>
);

export default BackgroundShop;
