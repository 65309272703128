import { withLayout } from '@moxy/next-layout';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import React from 'react';
import tw from 'twin.macro';

import apolloClient from '../apolloClient';
import BackgroundHomePage from '../components/Background/HomePage';
import {
  BackgroundSectionFadeX,
  BackgroundSectionWrapper,
} from '../components/Background/Section';
import ButtonLink from '../components/ButtonLink';
import DecorationStitches from '../components/DecorationStitches';
import HowItWorks from '../components/HowItWorks';
import LayoutFullWidth from '../components/Layout/FullWidth';
import PageMeta from '../components/PageMeta';
import ProductClaim from '../components/ProductClaim';
import ProductGrid from '../components/ProductGrid';
import ProductGridLarge from '../components/ProductGridLarge';
import ShopCard from '../components/ShopCard';
import ShopGrid from '../components/ShopGrid';
import { Container, H3 } from '../components/Styled';
import TagGrid from '../components/TagGrid';
import Video from '../components/Video';
import { HomeDocument, HomeQuery } from '../generatedGraphql';
import ctaBackgroundImage from '../images/ctaBackground.svg';

const StyledSection = tw(Container)`relative`;

export const getStaticProps: GetStaticProps<{
  exploreShops: HomeQuery['explore_shops'];
  featuredCollection1: HomeQuery['featured_collection_1'][0] | null;
  featuredCollection2: HomeQuery['featured_collection_2'][0] | null;
  featuredCollection3: HomeQuery['featured_collection_3'][0] | null;
  featuredShops: HomeQuery['featured_shops'];
  freeProduct: HomeQuery['free_products'][0] | null;
  tags: HomeQuery['tags'];
}> = async () => {
  const { data } = await apolloClient.query({ query: HomeDocument });

  return {
    props: {
      exploreShops: data.explore_shops || [],
      featuredShops: data.featured_shops || [],
      freeProduct: data.free_products[0] || null,
      featuredCollection1: data.featured_collection_1[0] || null,
      featuredCollection2: data.featured_collection_2[0] || null,
      featuredCollection3: data.featured_collection_3[0] || null,
      tags: data.tags || [],
    },
    revalidate: 60,
  };
};

type HomePageProps = InferGetStaticPropsType<typeof getStaticProps>;

const HomePage = ({
  exploreShops,
  featuredCollection1,
  featuredCollection2,
  featuredCollection3,
  featuredShops,
  freeProduct,
  tags,
}: HomePageProps) => {
  return (
    <>
      <PageMeta title="Home" />
      <BackgroundSectionWrapper tw="-top-16">
        <div tw="absolute w-full left-0 top-0 overflow-hidden z-0">
          <BackgroundHomePage tw="relative block left[-75%] lg:left-0 width[250%] sm:width[200%] lg:w-full" />
          <BackgroundSectionFadeX />
        </div>
        <div tw="absolute w-full left-0 top-0 right-0 z-0 hidden xl:block max-width[86rem] mx-auto">
          <DecorationStitches tw="absolute top-16 left-0 text-purple-300" />
          <DecorationStitches tw="absolute top-32 left-16 text-purple-300 transform rotate-180" />
          <DecorationStitches tw="absolute top-16 right-0 text-purple-300 transform[scaleX(-1)]" />
          <DecorationStitches tw="absolute top-32 right-16 text-purple-300 transform[scaleY(-1)]" />
        </div>
      </BackgroundSectionWrapper>
      <div tw="relative space-y-16 md:space-y-24 mb-24">
        <StyledSection tw="grid sm:grid-cols-2 lg:grid-cols-12 gap-8 pb-8 pt-8">
          <div tw="text-purple-600 lg:col-span-6">
            <h1 tw="font-serif text-3xl sm:text-4xl">
              Cross stitch just went digital
            </h1>
            <ul tw="text-lg md:text-xl lg:text-2xl list-disc py-2 pr-2 pl-4 ml-2 mt-4 sm:mt-8">
              <li>Change thread and fabric color</li>
              <li>Automatic frame fit preview</li>
              <li>Track your progress from any device</li>
            </ul>
            {freeProduct && (
              <ButtonLink
                hue="vibrant"
                size="large"
                to={{ productSlug: freeProduct.slug, type: 'signUp' }}
                tw="mt-4"
              >
                Claim Your Free Chart
              </ButtonLink>
            )}
          </div>

          <div
            css={{
              backgroundImage: `url(${ctaBackgroundImage})`,
              backgroundPositionX: '50%',
              backgroundRepeat: 'no-repeat',
            }}
            tw="flex items-center bg-cover py-16 px-2 md:(-my-6) lg:(col-span-6 py-12 -my-8)"
          >
            <Video />
          </div>
        </StyledSection>
        {featuredCollection1 &&
          featuredCollection1.products_collections.length > 0 && (
            <StyledSection>
              <h2 tw="font-bold text-purple-500 bg-purple-100 rounded-2xl text-center text-xl sm:text-2xl md:text-3xl py-3 md:py-5">
                {featuredCollection1.name}
              </h2>
              <ProductGrid
                products={featuredCollection1?.products_collections.map(
                  ({ product }) => product,
                )}
                tw="mt-6 md:mt-8"
              />
            </StyledSection>
          )}
        <StyledSection>
          <HowItWorks />
        </StyledSection>
        {featuredShops.length > 0 && (
          <StyledSection>
            <H3>Featured Designers</H3>
            <div tw="grid grid-cols-2 gap-4 sm:gap-8 lg:gap-16">
              {featuredShops.map((shop) => (
                <ShopCard
                  color={shop.color}
                  image={shop.icon_file.s3_url}
                  key={shop.id}
                  name={shop.name}
                  products={shop.products}
                  slug={shop.slug}
                />
              ))}
            </div>
          </StyledSection>
        )}
        {featuredCollection2 &&
          featuredCollection2.products_collections.length > 0 && (
            <ProductGridLarge
              color={[250, 217, 168]}
              description="Editors’ Picks"
              name={featuredCollection2.name}
              products={featuredCollection2?.products_collections.map(
                ({ product }) => product,
              )}
            />
          )}
        <StyledSection>
          <H3>Browse by Tag</H3>
          <TagGrid size="large" tags={tags} tw="mt-8" />
        </StyledSection>
        {featuredCollection3 &&
          featuredCollection3.products_collections.length > 0 && (
            <ProductGridLarge
              color={[168, 250, 176]}
              description="Editors’ Picks"
              flip
              name={featuredCollection3.name}
              products={featuredCollection3?.products_collections.map(
                ({ product }) => product,
              )}
            />
          )}
        {exploreShops.length > 0 && (
          <StyledSection>
            <H3>Explore These Designers</H3>
            <ShopGrid shops={exploreShops} tw="mt-8" />
          </StyledSection>
        )}
        {freeProduct && (
          <StyledSection>
            <ProductClaim product={freeProduct} />
          </StyledSection>
        )}
      </div>
    </>
  );
};

export default withLayout(
  <LayoutFullWidth headerProps={{ background: 'lightest' }} />,
)(HomePage);
