import { TinyColor } from '@ctrl/tinycolor';
import React from 'react';
import tw from 'twin.macro';

import { rgbToCssColor } from '@chartsy/shared/utils';

import { CollectionProductFragment, Scalars } from '../generatedGraphql';
import BackgroundSection, {
  BackgroundSectionContainer,
  BackgroundSectionFadeX,
  BackgroundSectionWrapper,
} from './Background/Section';
import ProductListItemMinimal from './ProductListItemMinimal';
import { AFTER_FADE_BOTTOM_CSS, Container } from './Styled';

interface ProductGridLargeProps {
  className?: string;
  color: Scalars['_rgb_int'];
  description: string;
  flip?: boolean;
  name: string;
  products: CollectionProductFragment[];
}

const ProductGridLarge = ({
  className,
  color,
  description,
  flip = false,
  name,
  products,
}: ProductGridLargeProps) => {
  const cssColor = rgbToCssColor(color);
  const tinyColor = new TinyColor(cssColor);
  const textColor = tinyColor.isDark()
    ? tinyColor.lighten(50)
    : tinyColor.darken(50);
  const descriptionColor = textColor.mix(0xffffff, 40);
  const startColor = tinyColor.mix(0xffffff, 70);
  const endColor = tinyColor.mix(0xffffff, 40);

  return (
    <BackgroundSectionWrapper>
      <BackgroundSectionContainer css={AFTER_FADE_BOTTOM_CSS}>
        <BackgroundSection
          css={!flip && tw`transform[scaleX(-1)]`}
          color="custom"
          start={startColor.toHexString()}
          end={endColor.toHexString()}
        />
        <BackgroundSectionFadeX />
      </BackgroundSectionContainer>
      <Container>
        <ul
          className={className}
          tw="relative grid grid-cols-2 gap-4 sm:(grid-cols-3 gap-8) lg:(grid-cols-4 gap-12)"
        >
          <li
            css={flip && tw`sm:(order-3! text-right justify-end)`}
            tw="order-1 flex items-center lg:col-span-2"
          >
            <div>
              <div
                css={{ color: descriptionColor.toHexString() }}
                tw="font-bold text-xl md:text-2xl"
              >
                {description}
              </div>
              <div
                css={{ color: textColor.toHexString() }}
                tw="font-serif text-2xl md:text-4xl mt-4"
              >
                {name}
              </div>
            </div>
          </li>
          {products.map((product, index) => (
            <li css={index <= 1 ? tw`order-1` : tw`order-4`} key={product.id}>
              <ProductListItemMinimal
                backgroundColor={cssColor}
                // Casting due to Hasura view columns being all nullable
                chartDimensions={product.chart_public as Scalars['dimensions']}
                dimBackground
                product={product}
                textColor={textColor.toHexString()}
                tw="shadow-lg"
              />
            </li>
          ))}
        </ul>
      </Container>
    </BackgroundSectionWrapper>
  );
};

export default ProductGridLarge;
